import React, { useState, useEffect } from "react";
import "../../css/Homepage/Mobilehometop.css";
import rightarrow from "../../images/homepage/Vector 9.png";
import vector1 from "../../images/homepage/Vector.png";
import vector2 from "../../images/homepage/Vector-2.png";
import vector3 from "../../images/homepage/Highlight 4.png";
import vector4 from "../../images/homepage/Line 3.png";
import video from "../../images/homepage/Video.png";
function Mobilehometop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setIsDesktop(width > 780 && height > 268);
      // console.log(width, height);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("isDesktop has changed:", isDesktop);
  }, [isDesktop]);
  return (
    <div className="Mobilehometop-container">
      <div className="Mobilehometop-title">
        <img src={vector3} className="Mobilehometop-icon3" />

        <div className="Mobilehometop-span">Welcome to FaceDAO</div>

        {/* <div className="Mobilehometop-span"> The Ultimate</div> */}
        <div className="Mobilehometop-span2">The Ultimate</div>
        <div className="Mobilehometop-span">SocialFi Experience</div>

        <div className="Mobilehometop-span3">
          Connect, chat, and earn in the world of Web 3.0
        </div>

        <button
          className="Mobilehometop-btn"
          onClick={() => window.open("/documents/FaceDao-Whitepaper.pdf")}
        >
          Whitepaper <img src={rightarrow} className="Mobilehometop-btn-img" />
        </button>
      </div>
      <div>
        <img src={video} className="Mobilehometop-video" />
      </div>
    </div>
  );
}

export default Mobilehometop;
