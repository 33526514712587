import React from "react";

import "../../css/Footer/Footer.css";
import { Link } from "react-router-dom";

import img1 from "../../images/footer/CTA-2.png";
import img2 from "../../images/footer/Logo.png";
import img3 from "../../images/footer/Facebook.png";
import img4 from "../../images/footer/Instagram.png";
import img5 from "../../images/footer/LinkedIn.png";
import img6 from "../../images/footer/Twitter.png";
import img7 from "../../images/footer/YouTube.png";
function Footer() {
  return (
    <div className="footer-container">
      <img src={img1} className="footer-img" />

      <div className="footer-links">
        <div className="footer-left-side">
          <div>
            <img src={img2} className="footer-icon-title" />
          </div>
          <div className="foot-p">
            A Web 3.0 app that revolutionizes social networking by integrating
            tokens and decentralized finance into a social platform
          </div>
          <div className="foot-i">
            <img src={img3} className="footer-icon1" />
            <img src={img4} className="footer-icon" />
            <img src={img5} className="footer-icon" />
            <img src={img6} className="footer-icon" />
            <img src={img7} className="footer-icon" />
          </div>
        </div>

        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Pages</h2>
            <Link to="/sign-up">Home</Link>
            <Link to="/">Portofolios</Link>
            <Link to="/">Services</Link>
            <Link to="/">Works</Link>
            <Link to="/">Career</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Company</h2>
            <Link to="/sign-up">Terms Conditions</Link>
            <Link to="/">Privacy Policy</Link>
            <Link to="/">Cookies</Link>
            <Link to="/">Careers</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Community</h2>
            <Link to="/sign-up">Help Center</Link>
            <Link to="/">Forum</Link>
            <Link to="/">Webinars</Link>
            <Link to="/">Professionals</Link>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <small className="website-rights">
            <p className="wr-fonts">
              {" "}
              © Copyright by FaceDao Inc. All rights reserved.
            </p>
          </small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
