import React from "react";
import "../../css/Homepage/Mobilehomemid.css";
import chat from "../../images/homemid/Chat.png";
import coin from "../../images/homemid/Coin.png";
import walletinapp from "../../images/homemid/walletinapp.png";

function Mobilehomemid() {
  return (
    <div className="Mobilehomemid-container">
      <div className="Mobilehomemid-wrap">
        <p className="Mobilehomemid-p">—— Key Features</p>
        <div className="Mobilehomemid-span-wrap">
          <div className="Mobilehomemid-span2">1 million+ Members</div>
          <div className="Mobilehomemid-span">Across the World and Growing</div>
        </div>

        <div className="Mobilehomemid-boxcontainer">
          <div className="Mobilehomemid-box1">
            <img src={chat} className="Mobilehomemid-icon1" />
            <p className="Mobilehomemid-pa">
              Experience the power of communication our versatile real-time
              chat. Enjoy a wide range of features, including voice and video
              calls, file transfers, and Red Packet sharing. Our group chat
              supports up to 6,000 members, ensuring your conversations remain
              inclusive and engaging.{" "}
            </p>
          </div>
          <div className="Mobilehomemid-box2">
            <img src={coin} className="Mobilehomemid-icon1" />

            <p className="Mobilehomemid-pa2">
              Get the opportunity to earn tokens as you engage with others,
              share your knowledge, and support the growth of the platform.
              Watch your digital assets grow as you deepen your connections
              within our thriving network.
            </p>
          </div>
          <div className="Mobilehomemid-box3">
            <img src={walletinapp} className="Mobilehomemid-icon1" />
            <p className="Mobilehomemid-pa">
              Our in-app wallet connects seamlessly with your existing
              decentralized wallets, allowing you to manage your digital assets
              with ease. Enjoy the freedom to access your funds, make
              transactions, and monitor your portfolio, all within our secure
              and user-friendly platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobilehomemid;
