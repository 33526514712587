import React, { useState } from "react";

import "../../css/Homepage/homevision.css";

import pic1 from "../../images/homevision/pic1.png";
import pic2 from "../../images/homevision/pic2.png";
import pic3 from "../../images/homevision/pic3.png";
import line from "../../images/homevision/Line 51.png";

function Homevision() {
  const [image, setImage] = useState("default.jpg");
  const [activeIndex1, setActiveIndex1] = useState(true);
  const [activeIndex2, setActiveIndex2] = useState(false);
  const [activeIndex3, setActiveIndex3] = useState(false);

  const checkNum = (index) => {
    if (index == 1) {
      setActiveIndex1(true);
      setActiveIndex2(false);
      setActiveIndex3(false);
    } else if (index == 2) {
      setActiveIndex1(false);
      setActiveIndex2(true);
      setActiveIndex3(false);
    } else if (index == 3) {
      setActiveIndex1(false);
      setActiveIndex2(false);
      setActiveIndex3(true);
    }

    console.log("````", activeIndex1, activeIndex2, activeIndex3);
  };
  return (
    <div className="homevision-container">
      <div className="homevision-wrap">
        <div className="homevision-left-top">
          <p className="homevision-p">—— The FaceDAO vision</p>
          <h1>
            <span className="homevision-span">A simple and attractive</span>
            <div>
              <span className="homevision-span">common </span>

              <span className="homevision-span2"> place for knowledge</span>
            </div>

            <span className="homevision-span">sharing </span>
          </h1>
          <p className="homevision-g">
            FaceDAO started amongst a group of crypto and DeFi enthusiasts when
            they were looking for a safe and effective way to share their latest
            discoveries in DeFi and earn tokens. The platform then quickly
            expanded into the wider social space.
          </p>
        </div>

        <div className="homevision-left-bot">
          <div className="homevision-left-bot-number">
            <div
              className={
                activeIndex1
                  ? "homevision-left-bot-number-active"
                  : "homevision-left-bot-number-inactive"
              }
              onClick={() => checkNum(1)}
            >
              1
            </div>

            <img src={line} className="homevision-num" />

            <div
              className={
                activeIndex2
                  ? "homevision-left-bot-number-active"
                  : "homevision-left-bot-number-inactive"
              }
              onClick={() => checkNum(2)}
            >
              2
            </div>
            <img src={line} className="homevision-num" />
            <div
              className={
                activeIndex3
                  ? "homevision-left-bot-number-active"
                  : "homevision-left-bot-number-inactive"
              }
              onClick={() => checkNum(3)}
            >
              3
            </div>
          </div>
          <div className="homevision-left-bot-pic">
            {activeIndex1 && (
              <>
                <div className="homevision-bot-wrap">
                  <span className="homevision-bot-t">Built-in Wallet</span>
                  <span className="homevision-bot-p">
                    The wallet supports a wide range of coins and enables
                    cross-chain functionality, ensuring assets are accessible
                    and manageable within the user-friendly platform.
                  </span>
                </div>
                <div className="homevision-btn-img-container">
                  <div className="homevision-btn-img-wrap">
                    <img src={pic1} className="homevision-btn-img" />
                    <div className="homevision-btn-img-wrap2"></div>
                  </div>
                </div>
              </>
            )}

            {activeIndex2 && (
              <>
                <div className="homevision-bot-wrap">
                  <span className="homevision-bot-t">Social to Earn</span>
                  <span className="homevision-bot-p">
                    Discover the perfect blend of community engagement and
                    earning potential. Connect with like-minded individuals,
                    share insights, unlock RedPacket surprises, all while
                    earning tokens for your participation.
                  </span>
                </div>
                <div className="homevision-btn-img-container">
                  <div className="homevision-btn-img-wrap">
                    <img src={pic2} className="homevision-btn-img" />
                    <div className="homevision-btn-img-wrap2"></div>
                  </div>
                </div>
              </>
            )}

            {activeIndex3 && (
              <>
                <div className="homevision-bot-wrap">
                  <span className="homevision-bot-t">FaceSDK</span>
                  <span className="homevision-bot-p">
                    Designed to help developers seamlessly create applications
                    that integrate with our platform. Unlock the potential of
                    FaceDAO by leveraging the powerful tools and resources to
                    build innovative and user-friendly solutions for
                    thecommunity.
                  </span>
                </div>
                <div className="homevision-btn-img-container">
                  <div className="homevision-btn-img-wrap">
                    <img src={pic3} className="homevision-btn-img" />
                    <div className="homevision-btn-img-wrap2"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homevision;
