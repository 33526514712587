import React, { useState, useEffect } from "react";
import Hometop from "./Hometop";
import Homemid from "./Homemid";
import Homechoose from "./Homechoose";
import "../../css/Homepage/homepage.css";
import Homevision from "./Homevision";
import Download from "../Download/Download";
import Contact from "../ServicesPage/Contact";
import Mobilehometop from "./Mobilehometop";
import Mobilehomemid from "./Mobilehomemid";
function HomePage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <div>
          <Mobilehometop />
          <Mobilehomemid />
          <Homechoose />
          <Homevision />
          <Download />
          <Contact />
        </div>
      ) : (
        <div className="homepage-container">
          <Hometop />
          <Homemid />
          <Homechoose />
          <Homevision />
          <Download />
          <Contact />
        </div>
      )}

      {/* <div className="homepage-container">
        <Hometop />
        <Homemid />
        <Homechoose />
        <Homevision />
        <Download />
        <Contact />
      </div> */}
    </>
  );
}

export default HomePage;
