import React, { useState, useEffect } from "react";
import "../../css/Homepage/Hometop.css";
import rightarrow from "../../images/homepage/Vector 9.png";
import vector1 from "../../images/homepage/Vector.png";
import vector2 from "../../images/homepage/Vector-2.png";
import vector3 from "../../images/homepage/Highlight 4.png";
import vector4 from "../../images/homepage/Line 3.png";

import { Link } from "react-router-dom";

function Hometop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setIsDesktop(width > 1024 && height > 268);
      // console.log(width, height);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("isDesktop has changed:", isDesktop);
  }, [isDesktop]);
  return (
    <div className="hometop-container">
      <div className="hometop-title">
        <img src={vector3} className="hometop-icon3" />
        <h1>
          <span className="hometop-span">Welcome to FaceDAO -</span>
          <div className="special-color-flex">
            <span className="hometop-span">The </span>
            <span className="hometop-span2">Ultimate </span>
            <span className="hometop-span">SocialFi</span>
          </div>

          <div className="hometop-addcolor"></div>
          <span className="hometop-span"> Experience</span>
          <p>Connect, chat, and earn in the world of Web 3.0</p>
        </h1>

        <img src={vector1} className="hometop-icon1" />
        <img src={vector2} className="hometop-icon2" />

        <button
          className="hometop-btn"
          onClick={() => window.open("/documents/FaceDao-Whitepaper.pdf")}
        >
          FaceDao Whitepaper{" "}
          <img src={rightarrow} className="hometop-btn-img" />
        </button>
        {/* <Link
          to="../../../document/FaceDao-Whitepaper-2023.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          FaceDao Whitepaper{" "}
        </Link> */}

        {/* <img src={vector4} className="hometop-icon4" /> */}
      </div>
    </div>
  );
}

export default Hometop;
