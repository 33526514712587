import React from "react";
import "../../css/Download/Download.css";

import download from "../../images/download/Image.png";
import apple from "../../images/download/apple.png";
import android from "../../images/download/android.png";

function Download() {
  return (
    <div className="Download-container">
      <div className="Download-wrap">
        <div className="Download-left">
          <img src={download} className="Download-btn-img" />
        </div>

        <div className="Download-right">
          <div className="Download-left-top">
            <h1>
              <span className="Download-span">
                Chat and Earn Anytime and Anywhere{" "}
              </span>
            </h1>
            <p className="Download-g">
              Start with our FaceDao App. Download now!
            </p>
            <div className="Download-btn-wrap">
              <div>
                <a href="https://apps.apple.com/au/app/facepro/id1659173018" target="_blank" rel="noopener noreferrer">
                  <img src={apple} className="Download-btn-apple" alt="Download on the App Store" />
                </a>
              </div>
              <div className="Download-btn-text-wrapper">
                <a href="/documents/facepro.apk" target="_blank" rel="noopener noreferrer" className="Download-btn-text">
                  Download APK
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
