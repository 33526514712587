import React from "react";
import "../../css/Homepage/Homechoose.css";

import statistic from "../../images/homechoose/Statistic.png";
import rightimg from "../../images/homechoose/Right.png";

function Homechoose() {
  return (
    <div className="homechoose-container">
      <div className="homechoose-wrap">
        <div className="homechoose-left">
          <div className="homechoose-left-top">
            <p className="homechoose-p">—— Why Choose Us</p>
            <h1>
              <span className="homechoose-span">Unlock </span>
              <span className="homechoose-span2">RedPacket</span>
              <span className="homechoose-span">
                surprises while you share and social{" "}
              </span>
            </h1>
            <p className="homechoose-g">
              Get tangible reward when you share your voice, knowledge, opinion
              on FaceDAO, where Social meets Finance.
            </p>
          </div>

          <div className="homechoose-left-bot">
            {/* <img src={statistic} className="homechoose-btn-img" /> */}
            <div className="homechoose-left-bot-font1">
              <div className="homechoose-left-bot-font-p1">1 Million+</div>
              <div className="homechoose-left-bot-font-p2">Happy Users</div>
            </div>

            <div className="homechoose-left-bot-font2">
              <div className="homechoose-left-bot-font-p1">6000</div>
              <div className="homechoose-left-bot-font-p2">
                Group Member Capacity
              </div>
            </div>
          </div>
        </div>

        <div className="homechoose-right">
          {/* <img src={rightimg} className="homechoose-btn-img" /> */}
        </div>
      </div>
    </div>
  );
}

export default Homechoose;
