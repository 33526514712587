import React, { useState } from "react";
import "../../css/ServicesPage/Contact.css";
import img1 from "../../images/contact/Icon.png";

function Contact() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [services, setServices] = useState("");
  const [budget, setBudget] = useState("");
  const [details, setDetails] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Code to submit the form data
  };

  return (
    <div className="contact-container">
      <div className="circle-container">
        <div className="title-container">
          <p>
            <span className="green">Get in touch </span>
            <span className="white">with us today!</span>
          </p>
        </div>

        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-wrap">
            <div className="form-group">
              <input
                type="text"
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                placeholder="Full Name"
                className="form-input"
              />
              <label htmlFor="fullName" className="form-label">
                {/* Full Name */}
              </label>
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
                className="form-input"
              />
              <label htmlFor="email" className="form-label"></label>
            </div>
          </div>

          <div className="form-wrap">
            <div className="form-group">
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                placeholder="Phone Number"
                className="form-input"
              />
              <label htmlFor="phoneNumber" className="form-label"></label>
            </div>
            <div className="form-group">
              <input
                type="text"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                placeholder="Company Name"
                className="form-input"
              />
              <label htmlFor="companyName" className="form-label"></label>
            </div>
          </div>
          <div className="form-wrap">
            <div className="form-group">
              <input
                type="text"
                id="services"
                value={services}
                onChange={(e) => setServices(e.target.value)}
                required
                placeholder="Services"
                className="form-input"
              />
              <label htmlFor="services" className="form-label"></label>
            </div>
            <div className="form-group">
              <input
                type="text"
                id="budget"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                required
                placeholder="Budget"
                className="form-input"
              />
              <label htmlFor="budget" className="form-label"></label>
            </div>
          </div>

          <div className="inputBox-container">
            <textarea
              id="details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              required
              placeholder="Describe your project"
              className="form-inputbox"
            />
            <label htmlFor="details" className="inputBox-label"></label>
          </div>
          <button type="submit" className="submit-btn">
            <img src={img1} className="img" />
            Get in Touch
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
