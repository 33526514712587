import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/Navbar/Navbar.css";
import logo1 from "../../images/logo/Logo-2.png";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logo1} className="img-logo" alt="FaceDao" />
            <p className="logo-p"> FaceDao</p>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                <p className="nav-item-p1">Home</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                <p className="nav-item-p2">About</p>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/service"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <p className="nav-item-p2">Services</p>
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link
                to="/nav-giftcard"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <p className="nav-item-p2">Downloads</p>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/nav-giftcard"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <p className="nav-item-p2">Works</p>
              </Link>
            </li> */}

            <li className="nav-item">
              <Link
                to="/contact-us"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <p className="nav-item-p2">Contact</p>
              </Link>
            </li>
          </ul>

          {/* <Link to="/" className="navbar-rightbtn" onClick={closeMobileMenu}>
            Lets ttt
          </Link> */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
